import React from "react";
import { Link } from "react-router-dom";
import io from "socket.io-client";
class Homepage extends React.Component {
  constructor(props) {
    super(props);
    const id = window.location.pathname;
    console.log(id);
    const socket = io(id);
    this.state = {
      socket: socket,
      id: id,
    };
    socket.on("startgame", (msg) => {
      console.log(msg, "startgame");
      window.location.replace(`/game/${id}`);
    });
  }
  render() {
    return (
      <>
        <h1>waiting for player</h1>
        <h1>id: {this.state.id.slice(-4)}</h1>
        <Link to="/">home</Link>
      </>
    );
  }
}

export default Homepage;
