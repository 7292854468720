import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Homepage from "./pages/Homepage";
import Game from "./pages/Game";
import WaitingRoom from "./pages/WaitingRoom";
import Solo from "./pages/Solo";
class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/*" element={<WaitingRoom />} />
          <Route path="/solo" element={<Solo />} />
          <Route path="/game/*" element={<Game />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default App;
