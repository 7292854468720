import React from "react";
import "../styles/game.css";
import "../styles/home.css";

class Homepage extends React.Component {
  render() {
    return (
      <>
        <h1>Welcome to Ulitmate Tic Tac Toe</h1>
        <button
          id="createGame"
          onClick={() =>
            fetch("/api/creategame").then((res) => {
//              alert(res.url)
              window.location = res.url;
            })
          }
        >
          create new game
        </button>
        <form action="/api/joingame" method="get">
          <label htmlFor="id">join game</label>
          <input
            min={1000}
            max={10000}
            type="number"
            placeholder="enter id"
            name="id"
          />
          <input type="submit" value="submit" />
          <a href="/solo">play only one screen</a>
        </form>
      </>
    );
  }
}
export default Homepage;
